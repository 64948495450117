import * as yup from "yup";

import { PlantCosts } from "../../../../../api/types/plants";
import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const costsSchema = yup.object<CafsForm<keyof PlantCosts>>({
  InitialCostPerUnit: CafsValueNumberValidation(),
  OtherInitialCostPHa: CafsValueNumberValidation(),
  InitialCostTotalPHa: CafsValueNumberValidation(),
  RunningCostPHaPY: CafsValueNumberValidation(),
  CostsRemarks: CafsValueRemarksValidation(),
  InitialCostPerUnitIsInvestment: CafsValueBooleanValidation(),
  OtherInitialCostIsInvestment: CafsValueBooleanValidation(),
  InitialCostTotalIsInvestment: CafsValueBooleanValidation(),
});
