import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { defaultReferencesListQueryParams } from "../../api/references";
import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { useModal } from "../../hooks/useModal";
import { usePagination } from "../../hooks/usePagiantion";
import { useSorting } from "../../hooks/useSorting";
import { Order } from "../../types/order";

import { useReferences } from "./api/useFetchReferences";
import { CreateReferenceModal } from "./CreateReferenceModal";
import { ReferencesTableHeader } from "./ReferencesTableHeader";

export function ReferencesView() {
  const { references, refetch, isPending } = useReferences();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [prevSearchPhrase, setPrevSearchPhrase] = useState<string | null>();
  const { open, handleOpen, handleClose } = useModal();
  const { formatMessage } = useIntl();

  const { page, pageSize, handlePageChange } = usePagination({
    page: defaultReferencesListQueryParams.page as number,
    pageSize: defaultReferencesListQueryParams.pageSize as number,
  });

  const { sorting, handleSortingChange } = useSorting({
    order: defaultReferencesListQueryParams.order as Order,
    orderBy: defaultReferencesListQueryParams.orderBy as string,
  });

  const [searchParamValue, setSearchParamValue] = useDebouncedSearch(
    "search",
    300,
  );

  useEffect(() => {
    // if previous searchPhrase is different then current one - reset page to 1
    const searchParam = urlSearchParams.get("search");
    if (searchParam !== prevSearchPhrase) {
      setUrlSearchParams(prevParams => {
        prevParams.set("page", "1");
        return prevParams;
      });
    }
    refetch();
    // Update the previous urlSearchParams state
    setPrevSearchPhrase(urlSearchParams?.get("search"));
  }, [urlSearchParams]);

  return (
    <div>
      <PageHeader
        text={formatMessage({
          id: "REFERENCES.TITLE",
          description: "References page title",
          defaultMessage: "References",
        })}
        rightPart={
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleOpen}>
            <FormattedMessage
              id="REFERENCES.ADD_NEW_BTN"
              description="Add new reference button"
              defaultMessage="Add new reference"
            />
          </Button>
        }
      />

      <PageSection>
        {!isPending && (
          <ReferencesTableHeader
            data={references?.References || []}
            total={references?.Total}
            searchPhrase={searchParamValue ?? ""}
            onSearchChange={setSearchParamValue}
            loading={isPending}
            pageInfo={{
              page: page !== 0 ? page - 1 : page,
              pageSize,
              total: references?.Total!,
            }}
            sortingInfo={sorting}
            onPaginationChange={handlePageChange}
            onSortingChange={handleSortingChange}
          />
        )}
        {isPending && <LoadingPlaceholder />}
      </PageSection>

      <CreateReferenceModal open={open} onClose={handleClose} />
    </div>
  );
}
