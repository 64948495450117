import { PlantCosts } from "../../../../../api/types/plants";
import { CafsVariable } from "../../../../../types/cafs-variable";
import { SelectOption } from "../../../../../types/select-option";
import { useFetchProducts } from "../../../../products-view/api/useFetchProducts";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { renderForCrop } from "../../../utils/render-for-crop";
import { renderForTree } from "../../../utils/render-for-tree";

export function useCostsVariables(): CafsVariable<PlantCosts>[] {
  const { products } = useFetchProducts(true);
  return [
    {
      name: "CostsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "InitialProductId",
      type: CafsVariableType.Select,
      options: products?.Products?.map(th => {
        return { label: th.Name, id: th.Id } as SelectOption;
      }),
      shouldRender: renderForTree,
    },
    {
      name: "InitialCostPerUnit",
      type: CafsVariableType.Info,
      shouldRender: renderForTree,
    },
    {
      name: "OtherInitialCostPHa",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
    },
    {
      name: "InitialCostTotalPHa",
      type: CafsVariableType.Input,
      shouldRender: renderForCrop,
    },
    {
      name: "RunningCostPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "InitialCostPerUnitIsInvestment",
      type: CafsVariableType.Boolean,
    },
    {
      name: "OtherInitialCostIsInvestment",
      type: CafsVariableType.Boolean,
      shouldRender: renderForTree,
    },
    {
      name: "InitialCostTotalIsInvestment",
      type: CafsVariableType.Boolean,
      shouldRender: renderForCrop,
    },
  ];
}
