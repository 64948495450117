import { Plant } from "../../../api/types/plants";

export const trimPlantData = (data: Record<keyof Plant, { Base: string }>) => {
  return {
    EnglishName: {
      Base: data.EnglishName?.Base?.trim(),
    },
    Specification: {
      Base: data.Specification?.Base?.trim(),
    },
    ConfidentialSpec: {
      Base: data.ConfidentialSpec?.Base?.trim(),
    },
    FormerRecName: {
      Base: data.FormerRecName?.Base?.trim(),
    },
    OrderName: {
      Base: data.OrderName.Base?.trim(),
    },
    ScientificName: {
      Base: data.ScientificName?.Base?.trim(),
    },
    GeneralRemarks: {
      Base: data.GeneralRemarks?.Base?.trim(),
    },
  };
};
