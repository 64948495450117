import { Input } from "../../../api/types/inputs";

export const trimInputData = (data: Record<keyof Input, { Base: string }>) => {
  return {
    EnglishName: {
      Base: data.EnglishName?.Base?.trim(),
    },
    NameSuffix: {
      Base: data.NameSuffix?.Base?.trim(),
    },
    ConfidentialSuffix: {
      Base: data.ConfidentialSuffix?.Base?.trim(),
    },
    FormerRecName: {
      Base: data.FormerRecName?.Base?.trim(),
    },
    GeneralRemarks: {
      Base: data.GeneralRemarks?.Base?.trim(),
    },
  };
};
