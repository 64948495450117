import * as React from "react";
import { useFormContext } from "react-hook-form";

import { FileCopy } from "@mui/icons-material";
import { Button } from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { RHFAutocompleteField } from "../../../components/rhf/RhfAutocomplete";
import { RhfFormField } from "../../../components/rhf/RhfFormField";
import { useDataSets } from "../../dashboard-view/api/useDatasets";

export function DatasetCopyForm() {
  const { datasets } = useDataSets();
  const { control } = useFormContext();
  const options = Array.isArray(datasets?.Datasets)
    ? datasets?.Datasets.map((d: Dataset) => ({
        id: d.Id!,
        label: d.Name!,
      }))
    : [];

  return (
    <div className="flex flex-col items-start justify-start gap-2">
      <RhfFormField name="sourceId">
        <RHFAutocompleteField
          control={control}
          name={"sourceId"}
          options={options}
          placeholder={"Source Dataset"}
        />
      </RhfFormField>

      <RhfFormField name="targetId">
        <RHFAutocompleteField
          control={control}
          name={"targetId"}
          options={options}
          placeholder={"Target Dataset"}
        />
      </RhfFormField>

      <div className="mt-4">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FileCopy />}>
          Copy
        </Button>
      </div>
    </div>
  );
}
